import { FC } from "react";
import styled from "styled-components";
import { P1 } from "../atoms/Paragraph";
import { H2 } from "../atoms/Heading";
import { StandardSection } from "../templates/StandardSection";

interface Props {
  id?: string;
  title: string;
  body?: string | string[];
  maxWidthText?: string;
  pt?: string;
}

export const ProductIntro: FC<React.PropsWithChildren<Props>> = ({
  id,
  title,
  body,
  maxWidthText,
  pt = "2.5rem",
}) => {
  return (
    <section id={id} aria-labelledby={`${id}-productintro-title`}>
      <StandardSection pt="0" pb="0">
        <H2 id={`${id}-productintro-title`} pt={pt} align="center">
          {title}
        </H2>
        {body && (
          <TextContainer maxWidthText={maxWidthText}>
            {Array.isArray(body) ? (
              body.map((p) => (
                <P1 align="center" key={p}>
                  {p}
                </P1>
              ))
            ) : (
              <P1 align="center">{body}</P1>
            )}
          </TextContainer>
        )}
      </StandardSection>
    </section>
  );
};

const TextContainer = styled.div<{ maxWidthText: string }>`
  max-width: ${(p) => p.maxWidthText || "46rem"};
  margin: 0 auto;
`;
